<template>
  <div style="min-height: 100vh">
    <van-cell-group title="请填写个人基本信息">
      <div slot="title" style="display: flex;justify-content: space-between">
        <div>
          请填写个人基本信息
        </div>
        <div>
          <a href="javascript:void(0);" @click="ToList()">注册列表</a>
        </div>
      </div>
    </van-cell-group>
    <van-form @submit="onSubmit">
      <van-field center
                 name="name"
                 label="姓名"
                 placeholder="请输入姓名"
                 v-model="userInfo.name"
                 :rules="[{ required: true, message: '请输入姓名' }]"/>
      <!--                 @blur="checkUserInfo"-->
      <van-field center
                 name="tel"
                 type="tel"
                 label="手机号"
                 maxlength="11"
                 placeholder="请输入手机号"
                 v-model="userInfo.tel"
                 :rules="[{ required: true, message: '请输入手机号' }]"
      />
      <!--                 @blur="checkUserInfo"-->
      <van-field center
                 name="idCode"
                 label="身份证号"
                 maxlength="18"
                 placeholder="请输入身份证号"
                 v-model="userInfo.idCode"
                 :rules="[{validator:userIdCardValidator,message: '请输入正确的身份证号'}]"
      />
      <van-field
          readonly
          clickable
          name="sexId"
          label="性别"
          placeholder="点击选择性别"
          :rules="[{ required: true, message: '请选择性别' }]"
      >
        <template #input>
          <van-radio-group v-model="userInfo.sexId" direction="horizontal">
            <van-radio :name="0">男</van-radio>
            <van-radio :name="1" checked-color="#ee0a24">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
          readonly
          clickable
          name="enterprise"
          label="申请企业"
          :value="centerData.centerName"
          placeholder="请选择企业"
          value-key="value"
          :rules="[{ required: true, message: '请选择申请企业' }]"
      />
      <van-field
          readonly
          clickable
          name="dept"
          label="所属部门"
          :value="userInfo.dept"
          placeholder="请选择所属部门"
          value-key="value"
          @click="deptShow"
          :rules="[{ required: true, message: '请选择所属部门' }]"
      />
      <van-field center label="本人照片" name="photo" :rules="[{ required: true, message: '请上传人脸照片' }]"
                 error-message="请确保照片脸部保持居中,否则可能会导致闸机识别不准确" error>
        <template #input>
          <van-uploader v-model="fileList"
                        :before-read="beforeRead"
                        :max-count="1" capture="user"/>
        </template>
        <template #right-icon>
          <van-image :src="require('@/assets/image/register/111.jpg')" width="100" height="100"></van-image>
        </template>
      </van-field>
      <van-field center label="附件" name="photo2" :rules="[{ required: true, message: '请上传审批附件' }]">
        <template #input>
          <van-uploader v-model="accessoryFileList"
                        :before-read="beforeRead2"
                        :max-count="1"
                        capture="user"/>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
    <van-popup v-model="userTypeShowPicker" round position="bottom">
      <van-picker
          show-toolbar
          :columns="userTypeColumns"
          @cancel="userTypeShowPicker = false"
          @confirm="userTypeOnConfirm"
      />
    </van-popup>
    <van-popup v-model="enterpriseShowPicker" round position="bottom">
      <van-picker
          show-toolbar
          :columns="enterpriseColumns"
          @cancel="enterpriseShowPicker = false"
          @confirm="enterpriseOnConfirm"
      />
    </van-popup>
    <van-popup v-model="deptShowPicker" round position="bottom" @open="openPopup">
      <van-picker
          show-toolbar
          value-key="departmentName"
          :columns="list"
          @cancel="deptShowPicker = false"
          @confirm="deptOnConfirm"
      >
        <van-search slot="columns-top" v-model="deptSearchValue" placeholder="请输入搜索关键词" @input="deptSearch"/>
      </van-picker>
    </van-popup>
    <van-popup v-model="userListShow" closeable round get-container="body" class="popup-children select-children">
      <div style="height: 20px"/>
      <div style="height: 5px"/>
      <h2 style="margin: 5px 10px">选择个人信息(如不是本人请不要选择)</h2>
      <div style="height: 5px"/>
      <van-cell center is-link v-for="user in userList" :title="user.name" :value="user.tel" @click="selectUser(user)">
        <div slot="icon">
          <van-image
              style="padding-right: 10px"
              round
              width="30px"
              height="30px"
              :src="user.photo?path+user.photo:'https://img01.yzcdn.cn/vant/cat.jpeg'"
          />
        </div>
      </van-cell>
      <div style="height: 10px"/>
      <div style="height: 5px"/>
    </van-popup>
    <van-overlay :show="showOverlay" @click="show = false" :lock-scroll="false">
      <div class="cropper-content" @click.stop style="overflow-y: scroll">
        <div class="cropper" style="text-align:center">
          <vueCropper
              style="padding-top: 10px"
              ref="cropper"
              :img="option.img"
              :outputSize="option.size"
              :outputType="option.outputType"
              :info="true"
              :full="option.full"
              :canMove="option.canMove"
              :canMoveBox="option.canMoveBox"
              :original="option.original"
              :autoCrop="option.autoCrop"
              :fixed="option.fixed"
              :fixedNumber="option.fixedNumber"
              :centerBox="option.centerBox"
              :infoTrue="option.infoTrue"
              :fixedBox="option.fixedBox"
              @realTime="realTime"
          ></vueCropper>
        </div>
        <div
            class="caiJiang_btn">
          <van-button type="default" style="width: 23%" plain @click="cropperCancal">取消</van-button>
          <van-button type="default" style="width: 23%" plain @click="rotateLeft">左旋转</van-button>
          <van-button type="default" style="width: 23%" plain @click="rotateRight">右旋转</van-button>
          <van-button type="primary" style="width: 23%" plain @click="cropperFinish">确定</van-button>
        </div>
        <van-notice-bar
            wrapable
            text="请观察裁剪后的照片、确保照片脸部保持蓝色框内居中、否则可能会导致闸机识别不准确。"
        />
        <!--预览效果图-->
        <div class="show-preview" style="position: relative">
          <div :style="previews.div" class="preview">
            <img :src="previews.url" :style="previews.img">
          </div>
          <div class="bg_face" :style="previews.div"></div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {beforeAvatarUpload} from "@/utils/imageConversion";
import {addEmp, getDept, getUserInfoByUserNameAndPhone} from "@/api/register/register";
import {mapGetters} from "vuex";
import ImageCompressor from 'image-compressor.js'

export default {
  name: "index",
  //组件引入
  components: {},
  //父子组件传值
  props: {},
  //计算属性，可以缓存，避免每次计算都要重新计算
  computed: {
    ...mapGetters({
      centerId: 'getCenterId',
      centerData: 'getCenterData',
      deptName: 'getDeptName'
    })
  },
  //filters 过滤器
  filters: {},
  //数据
  data() {
    return {
      showOverlay: false,//显示弹层
      list: [],
      deptSearchValue: '',//部门搜索值
      userTypeShowPicker: false,//选择器
      enterpriseShowPicker: false,//企业
      deptShowPicker: false,//部门
      userInfo: {
        fileList: []
      },//个人信息
      //用户类型
      userTypeColumns: [
        {text: '教师', value: '1'},
        {text: '学生', value: '2'},
      ],
      //企业
      enterpriseColumns: [
        {text: '企业1', value: '1-1'},
        {text: '企业2', value: '2-2'},
      ],
      //部门1
      deptColumns: [],
      path: '',//文件预览路径
      userList: [],//存储数据库中存在的用户信息
      userListShow: false,
      fileList: [],//头像列表
      accessoryFileList: [],//证明照片
      // 裁剪组件的基础配置option
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.5, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 300, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例
        // fixedNumber: [7, 5], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: false // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      previews: {},//实时预览
    }
  },
  //在模板渲染成html前调用,即通常初始化某些属性值,然后再渲染成视图。
  created() {
  },
  //在模板渲染成html后调用，通常是初始化页面完成后，再对html的dom节点进行一些需要的操作。
  mounted() {
    this.getDeptList()
  },
  activated() {
  },
  //方法 处理逻辑
  methods: {
    deptSearch(value) {
      if (!value) {
        this.list = this.deptColumns
        return
      }
      this.list = this.deptColumns.filter(item => item.departmentName.includes(value))
    },
    userIdCardValidator(val) {
      if (!val) {
        return true
      }
      return /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(val) || /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$/.test(val)
    },
    /**
     * 跳转注册列表
     */
    ToList() {
      console.log('注册列表')
      if (!this.userInfo.name || !this.userInfo.tel) {
        this.$notify({type: 'warning', message: '请先输入姓名和手机号！'})
        return
      }
      let data = {
        name: this.userInfo.name,
        tel: this.userInfo.tel
      }
      this.$store.dispatch('SET_USER_DATA', data).then(res => {
        this.$router.push({path: '/RegisterList'})
      })
    },
    /**
     * 选择用户信息
     */
    selectUser(user) {
      console.log(user, 'user');
      this.userInfo = {
        ...user,
        dept: this.deptName(user.maindeptId),
        deptId: user.maindeptId
      }
      this.userListShow = false
    },
    /**
     * 验证用户信息，查询是否存在
     */
    checkUserInfo(event) {
      if (!this.userInfo.name) {
        return
      }
      if (!this.userInfo.tel) {
        return
      }
      this.$store.commit('SET_USER_DATA', {name: this.userInfo.name, tel: this.userInfo.tel})
      this.$toast.loading({message: '正在搜索...', duration: 0})
      let data = {
        centerId: this.centerData.id,
        userName: this.userInfo.name,
        tel: this.userInfo.tel,
        enterpriseType: this.centerData.centerType
      }
      getUserInfoByUserNameAndPhone(data).then(res => {
        if (res.code === 0 && res.data) {
          console.log(res, 'res getUserInfoByUserNameAndPhone');
          if (res.data.date.length > 0) {
            this.userListShow = true
            this.userList = res.data.date
            this.path = res.data.path
          }
        }
        this.$toast.clear()
      }).catch(err => {
        console.log(err, 'err');
        this.$toast.fail('通过用户名手机号查询出错!')
      })
    },
    /**
     * 表单提交
     */
    onSubmit(values) {
      console.log(values, 'values');
      console.log(this.userInfo, 'userInfo');
      let data = {
        userId: this.userInfo.tid || this.userInfo.empId,
        tel: values.tel,//手机号
        maindeptId: this.userInfo.deptId,//部门
        idCode: values.idCode,//身份证
        name: values.name,//姓名
        photo: values.photo[0].url.replace(/^data:image\/\w+;base64,/, ""),//头像
        photo2: values.photo2[0].url.replace(/^data:image\/\w+;base64,/, ""),//附件证明
        sexId: values.sexId,//性别
        enterpriseType: this.centerData.centerType,//企业类型
        centerId: this.centerData.id//企业id
      }
      /* if (this.userInfo.id) {
         //编辑
         updateEmp(data).then(res => {
           console.log(res, 'res updateEmp');
           if (res.code === 1) {
             this.$toast.fail(res.msg || '更新注册资料异常')
             return
           }
           if (res.code === 0) {
             this.$toast.success(res.msg || '更新注册资料成功!')
             this.$router.push({path: '/RegisterList'})
           }
         }).catch(err => {
           console.log(err, 'err');
           this.$toast.fail('更新注册资料异常')
         })
       } else {*/
      //新增
      addEmp(data).then(res => {
        console.log(res, 'res addEmp');
        if (res.code === 1) {
          this.$toast.fail(res.msg || '提交注册资料异常')
        }
        if (res.code === 0) {
          this.$toast.success(res.msg || '提交注册资料成功!')
          this.$router.push({path: '/RegisterList'})
        }
      }).catch(err => {
        console.log(err, 'err');
        this.$toast.fail('提交注册资料异常')
      })
      // }
    },
    /**
     * 用户类型确认
     */
    userTypeOnConfirm(value) {
      console.log(value, 'value');
      this.userInfo.userTypeName = value.text
      this.userTypeShowPicker = false
    },
    /**
     * 企业选择确认
     */
    enterpriseOnConfirm(value) {
      this.userInfo.enterprise = value.text
      this.enterpriseShowPicker = false
    },
    /**
     * 部门选择确认
     */
    deptOnConfirm(value) {
      console.log(value, 'value');
      this.userInfo.dept = value.departmentName
      this.userInfo.deptId = value.id
      this.deptShowPicker = false
    },
    beforeRead(file) {
      const _this = this
      console.log(file, 'file');
      //压缩图片
      // _this.$toast.loading({duration: 0, message: '上传中...', overlay: true, forbidClick: true,})
      new ImageCompressor(file, {
        // quality: 0.1, //压缩质量
        checkOrientation: true,//指示是否读取图像的Exif方向值(仅为JPEG图像)，然后使用该值自动旋转或翻转图像。默认true
        success(resolve) {
          console.log(resolve, 'res');
          beforeAvatarUpload(resolve).then(beforeAvatar => {
            console.log(beforeAvatar, 'beforeAvatar');
            let reader = new FileReader()
            reader.readAsDataURL(beforeAvatar)

            reader.onload = () => {
              _this.option.img = reader.result
              _this.userInfo.image = reader.result//保存当前的图片信息
              _this.showOverlay = true
              // _this.$toast.success('上传成功')
              // _this.fileList.push({url: _this.userInfo.image, isImage: true})
              /*getBaiDuAccessToken().then(res => {
                getCheckFace({
                  image: reader.result.replace(/^data:image\/\w+;base64,/, ""),
                  image_type: 'BASE64',
                  face_type: 'IDCARD',
                  face_field: 'quality,age,beauty'
                }, res.access_token).then(check => {
                  _this.$toast.clear()
                  NProgress.done()
                  if (check.error_code === 222202) {
                    _this.$toast.fail({message: '请检查人脸质量。正确上传人脸信息!', overlay: true, forbidClick: true, duration: 3000})
                    return false
                  }
                  if (check.error_code === 222308) {
                    _this.$toast.fail({message: '图片非法 含有政治敏感人物!', overlay: true, forbidClick: true, duration: 3000})
                    return
                  }
                  if (check.error_code === 222307) {
                    _this.$toast.fail({message: '图片非法 鉴黄未通过!', overlay: true, forbidClick: true, duration: 3000})
                    return
                  }
                  if (check.error_code === 223129) {
                    _this.$toast.fail({message: '请使用面向正前方的人脸图片!', overlay: true, forbidClick: true, duration: 3000})
                    return
                  }
                  if (check.error_code === 222304) {
                    _this.$toast.success('上传成功')
                    _this.userInfo.fileList.push({url: _this.userInfo.image, isImage: true})
                    return
                  }
                  /!* if (!check.result) {
                     _this.$toast.fail({message: '请上传正确的人脸照片!', overlay: true, forbidClick: true, duration: 3000})
                     return false
                   }*!/
                  if (check.result.face_list[0].face_probability < 1) {
                    _this.$toast.fail({message: '这不是一张人脸图,请重新上传!', overlay: true, forbidClick: true, duration: 3000})
                    return
                  }
                  if (check.result.face_list[0].face_probability === 1) {
                    _this.$toast.success('图片上传成功')
                    _this.userInfo.fileList.push({url: _this.userInfo.image, isImage: true})
                    return true
                  }
                  _this.$toast.fail({message: '人脸识别异常、如无上传图片请重试!', overlay: true, forbidClick: true, duration: 3000})
                })
              }).catch(err => {
                NProgress.done()
                _this.$toast.fail({message: '人脸识别异常、请重试!', overlay: true, forbidClick: true, duration: 3000})
              })*/
            }
          })
        },
        error(err) {
          console.log(err.message);
        },
      });
    },
    beforeRead2(file) {
      const _this = this
      //压缩图片
      _this.$toast.loading({duration: 0, message: '上传中...', overlay: true, forbidClick: true,})
      new ImageCompressor(file, {
        // quality: 0.1, //压缩质量
        checkOrientation: true,//指示是否读取图像的Exif方向值(仅为JPEG图像)，然后使用该值自动旋转或翻转图像。默认true
        success(resolve) {
          beforeAvatarUpload(resolve).then(beforeAvatar => {
            console.log(beforeAvatar, 'beforeAvatar');
            let reader = new FileReader()
            reader.readAsDataURL(beforeAvatar)
            reader.onload = () => {
              _this.userInfo.photo2 = reader.result
              _this.$toast.success('上传成功')
              _this.accessoryFileList.push({url: _this.userInfo.photo2, isImage: true})
            }
          })
        },
        error(err) {
          console.log(err.message);
        },
      });
    },
    getDeptList() {
      let data = {
        centerId: this.centerData.id,
        enterpriseType: this.centerData.centerType
      }
      console.log(data, 'data');
      getDept(data).then(res => {
        if (res.code === 0 && res.data) {
          this.deptColumns = res.data
          this.$store.commit('SET_DEPT_LIST', res.data)
        }
      }).catch(err => {
        this.$toast.fail('获取部门列表失败,请重试！')
      })
    },
    deptShow() {
      if (this.userInfo.id) {
        return
      }
      if (this.deptColumns.length === 0) {
        this.getDeptList()
        this.deptShowPicker = true
      }
      this.deptShowPicker = true
    },
    openPopup() {
      this.list = this.deptColumns
    },
    cropperCancal() {
      this.fileList = []
      this.option.img = ''
      this.userInfo.image = ''
      this.showOverlay = false
    },
    // 点击裁剪，这一步是可以拿到处理后的地址
    cropperFinish() {
      const _this = this
      _this.$refs.cropper.getCropData((data) => {
        console.log(data, 'data');
        _this.userInfo.image = data//保存当前的图片信息
        _this.fileList.push({url: _this.userInfo.image, isImage: true})
        _this.showOverlay = false
        // var fileName = 'goods' + this.fileinfo.uid
        /*  //上传阿里云服务器
          client().put(fileName, data).then(result => {
            this.dialogVisible = false
            this.picsList.push(result.url)
          }).catch(err => {
            console.log(err)
            this.loading = false
          })*/
      })
    },
    //向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft()
    },
    //向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight()
    },
    realTime(data) {
      console.log(data, 'data');
      this.previews = data
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-children {
  width: 80%;
  max-height: 50vh;
  padding: 10px 0;
  overflow-y: scroll;

  .child_col {
    text-align: center;

    div {
      overflow: hidden;
      margin: 8px auto;
      width: 60px;
      height: 60px;
      background-color: #7ab893;
      border-radius: 50%;
      line-height: 61px;
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.select-children {
  ::v-deep .van-cell img {
    width: 30px !important;
    height: 30px !important;
  }
}

::v-deep .van-cell-group__title {
  background-color: #f6f6f6 !important;
}

.overlay_file {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
}

.cropper-content {
  width: 100%;
  height: 100%;
  background: #FFFFFF;

  .cropper {
    width: 100%;
    height: 50%;
    //padding-top: 10px;
    //background: #FFFFFF;
  }

  .show-preview {
    width: 100%;
    margin-top: 20px;
    height: 40%;

    .preview {
      overflow: hidden;
      border: 1px solid #67c23a;
      background: #cccccc;
      margin: 0 auto;
      height: 200px;
    }

    .bg_face {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      //width: 100%;
      //height: 100%;
      background: url("../../assets/image/register/faceImage.png") center center / cover no-repeat;
    }
  }
}

.caiJiang_btn {
  width: 90%;
  margin: 20px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
