import {compress, compressAccurately} from "image-conversion";
import {number} from "echarts/lib/export";
//把图片文件作为参数传递到方法中
export function beforeAvatarUpload(file) {
    return new Promise((resolve) => {
        // 压缩到100KB,这里的100就是要压缩的大小,可自定义
        function ys(bl){
            console.log(bl,'压缩比例');
            compress(file, Number(0.4)).then(res => {
                resolve(res);
            });
        }
        //等比例缩放
        console.log(Math.ceil(file.size / 1024 / 1024),'文件大小');
        switch (Math.ceil(file.size/1024/1024)){
            case 1:
                ys(0.1)
                break;
            case 2:
                ys(0.3)
                break;
            case 3:
                ys(0.2)
                break;
            case 4:
                ys(0.15)
                break;
            case 5:
                ys(0.1)
                break;
            case 6:
                ys(0.05)
                break;
            default:
                ys(0.01)
        }
    })
}
